@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("./fonts/Inter-Medium.woff2") format("woff2"),
    url("./fonts/Inter-Medium.woff") format("woff");
}

* {
  margin: 0;
}

html,
body {
  height: 100vh;
  height: 100dvh;
  width: 100vw;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Inter;
  overflow: hidden;
  position: fixed;
}

input,
button {
  all: unset;
  box-sizing: border-box;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

/*
  7. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
  font: inherit;
}
/*
  8. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

* {
  box-sizing: border-box;
  user-select: none;
  -webkit-user-select: none;
}
